import { OltTabDirective } from './tab-index/tab-index';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountdownModule } from 'ngx-countdown';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { OltCoreModule } from '@outerlimitstech/ngx-app-core';
import { BgcslibModule } from 'bgcslib';

import { ToastrModule } from 'ngx-toastr';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

export function getBsDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    dateInputFormat: 'M/D/YYYY',
    containerClass: 'theme-default',
    showWeekNumbers: false
  });
}



@NgModule({
  declarations: [
    OltTabDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    CountdownModule,
    OltCoreModule,
    BgcslibModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      positionClass: 'toast-top-full-width',
      progressBar: true,
      tapToDismiss: true,
      progressAnimation: 'increasing',
      timeOut: 3000,
    }),
    LoggerModule.forRoot({
      serverLoggingUrl: `/api/logs`,
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR,
    }),
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    CountdownModule,
    OltCoreModule,
    BgcslibModule,
    OltTabDirective,
  ],
  providers: [
    {
      provide: BsDatepickerConfig,
      useFactory: getBsDatepickerConfig
    }
  ],
})
export class SharedModule { }
