import { Subscription } from 'rxjs';
import { Directive, Input, ElementRef, HostListener,  AfterViewInit, OnDestroy, inject } from '@angular/core';
import { TabIndexService } from './tab-index.service';
import { delay } from 'rxjs/operators';

type IKNOWISNUMBER = any;
type IKNOWISSTRING = any;

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[tabIndex]'
})
export class OltTabDirective implements AfterViewInit, OnDestroy {
  observable!: Subscription;

  // tslint:disable-next-line: variable-name
  private _index!: number;
  get index(): IKNOWISNUMBER {
    return this._index;
  }
  @Input('tabIndex')
  set index(i: IKNOWISSTRING) {
    this._index = parseInt(i, 10);
  }
  @HostListener('keydown', ['$event'])
  onInput(e: any) {
    if (e.which === 13) {
      this.tabService.selectedInput.next(this.index + 1);
      e.preventDefault();
    }
  }

  private el = inject(ElementRef);
  private tabService = inject(TabIndexService);

  ngOnDestroy(): void {
    if (this.observable != null) {
      this.observable.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.observable = this.tabService.selectedInput
      .pipe(delay(0))
      .subscribe((i) => {
        if (i === this.index) {
          this.el.nativeElement.focus();
        }
      });
  }
}
